.contact-slip {
    padding: 8px 16px;
    margin-top: 8px;
    height: 56px;
    font-size: 14px;
    border-radius: 8px;
    border-bottom: 1px solid #dfdfdf;
}

.contact-slip > * {
    margin: 0;
}

.contact-slip .icon {
    height: 32px;
    width: 32px;
    float: left;
    margin-right: 16px;
    border-radius: 6px;
    color: var(--dark-text);
    background-color: var(--choco-gold);
    background: var(--orange-gradient);
    transition: all 0.2s ease;
}

.contact-slip:hover .icon {
    box-shadow: var(--multiple-shadow);
    cursor: pointer;
}

.contact-slip .icon svg {
  font-size: 1.25rem;
}

.contact-slip:hover a {
    color: var(--choco-gold);
}
.contact-slip:hover {
  background-color: var(--grey);
}

.contact-slip small {
    color: var(--light-text);
}

/*---------- big logo ---------- */
.big-logo {
    opacity: 0.24; /* You can also use 24% */
    transition: opacity 0.4s ease-out;
    animation: marquee 20s linear infinite;
}
.big-logo:hover {
    opacity: 0.8; /* You can also use 80% */
}

@keyframes marquee {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-500%);
    }
}

/* --------- square card ----------- */
.sqCard {
  flex-grow: 1;
  width: 300px;
  height: fit-content;
  color: var(--white);
  background-color: transparent;
  transition: all .40s ease-in;
}


.sqCard-image img {
    width: 120px;
    transform: rotateY(0deg);
}

.sqCard-text {
  padding: 8px 24px;
}

.sqCard-image.animate img {
    animation: spinY 1000ms linear 2; 
    /* cubic-bezier(0, 0, 0.07, 0.98); */
}

@keyframes spinY {
    0% { transform: rotateY(0deg); }
    50% { transform: rotateY(270deg); }
    100% { transform: rotateY(360deg); }
  
}

.sqCard-image {
    width: 200px;
    margin: auto;
    /* border-radius: 100%; */
    border-radius: 50px;
    height: 200px;
    background-color: var(--dark-50);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* ----------- image card --------------- */
.image-card {
    --card-height: 320px;
    width: 250px;
    height: var(--card-height);
    border-radius: 10px;
    background-color: var(--dark-50);
    overflow: hidden;
}

.image-card-img {
    height:  var(--card-height);
    border-radius: inherit;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: height 0.4s ease;
}

.image-card-text {
    color: transparent;
    width: 0;
    margin: 0;
    padding: 0;
}

.image-card-text h3 {
    margin: 0;
    font-weight: normal;
}

.image-card:hover .image-card-img {
    height: 250px;
}

.image-card:hover .image-card-text {
    background-color: var(--dark-50);
    padding: 10px 20px;
    color: var(--white);
    text-wrap: nowrap;
}

/* ------------ email forms --------------- */
.email-form {
    min-width: 300px;
    width: 40%;
    padding: 24px;
    border-radius: 10px;
    background-color: var(--white);
    box-shadow: 0 4px 4px 8px #00000040;
  }

.email-form h2 {
  color: var(--dark-50);
  margin-top: 0;
}

label {
  display: block;
  margin-bottom: 4px;
}
input[type="text"],
input[type="email"] {
  height: 40px;
}

input[type="text"],
input[type="email"],
textarea {
  font-family: karla, sans-serif;
  color: var(--whitish);
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid var(--light-50);
  outline-color: var(--light-50);
  background-color: var(--whitish);
  transition: outline-color 400ms ease;
}
    
input[type="text"]:focus-visible,
input[type="email"]:focus-visible,
textarea:focus-visible {
  outline: 1px solid var(--dark-50);
  outline-offset: -2px;
}

button[type="submit"] {
  width: 100%;
  box-shadow: none;
}
  
button[type="submit"]:hover {
    outline-color: var(--dark-50);
    border-color: var(--dark-50);
}

/* ---------- IMAGE SLIDER ----------- */
.slide-object {
  position: relative;
  height: 400px;
  width: 80%;
  margin: auto;
  border-radius: 32px;
  overflow: hidden;
}
  
.slide-window {
  width: 100%;
  height: 100%;
  display: inline-flex;
  transition: transform 0.3s ease-in-out;
}

.slide-screen {
    flex: 0 0 100%;
    display: flex;
    background-color: var(--dark-100);
    color: var(--white);
    justify-content: space-between;
    transition: background-color 0.3s ease-in-out;
}

.slide-screen:hover {
    background-color: var(--dark-h-100);
}

.slide-screen__image {
    width: 40%;
    margin: 10px;
    border-radius: 24px;
    object-fit: cover;
}

.slide-screen__text {
    padding: 50px;
    display: flex;
    flex-wrap: wrap;
}

.dots-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  padding: 8px 16px;
  /*background-color: var(--light-text);*/
  border-radius: 24px;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  gap: 12px;
}

.dot {
  display: inline-block;
  width: 30px;
  height: 6px;
  border-radius: 50px;
  background-color: var(--grey);
  cursor: pointer;
  transition: all 0.2s ease;
}
.dot.inactive:hover {
    box-shadow: 0 8px 8px -1px #333;
    background-color: var(--dark-50);
}

.dot.active {
  background-color: var(--dark-50);
}

/* ---------- BACK TO TOP ------------- */
.back-to-top-button {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 80px;
  right: 40px;
  cursor: pointer;
  font-size: 14px;
  color: var(--white);
  opacity: 0.6;
  border: none;
  border-radius: 100%;
  background-color: var(--dark-50);
  box-shadow: var(--multiple-shadow);
  transition: all 0.2s ease;
}

.back-to-top-button:hover {
  opacity: 1;
}