/* global variables */
:root {
    /* GLOBAL DIMENSIONS */
    --cta-width: 192px;
    --card-width: 320px;

    /* THEME COLORS 2*/
    --light-50: #CCBDFC;
    --dark-50: #662d91; /* 5624E9 */;
    --dark-100: #181D27;
    --dark-h-100: #272f3f;

    /* CUSTOM COLORS */
    --white: #fefefe;
    --whitish: #efefaff;
;
    --grey: #efefef;
    --dark-grey: #909090;

    /* TEXT COLORS */
    --light-text: #9f9f9f;
    --dark-text: rgb(26, 26, 26);

    /* SHADOWS & GRADIENTS */
    --orange-gradient: radial-gradient( circle farthest-corner at 64% 24%,  rgba(255,209,67,1) 0%, rgba(255,145,83,1) 90% );    
    --brown-gradient: linear-gradient(45deg, var(--dark-50) 0%, var(--dark-50) 75%);
    

    --card-shadow: 0px 3px 6px 0px rgba(140, 149, 159, 0.15);
    --multiple-shadow:  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
                        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
                        0 12.5px 10px rgba(0, 0, 0, 0.06),
                        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
                        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
                        0 100px 80px rgba(0, 0, 0, 0.112);
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'karla', 'montserrat', 'poppins', sans-serif;
    background-color: var(--white);
    font-size: 16px;
}

a {
    text-decoration: none;
    color: var(--dark-text)
}

p {
    font-size: 16px;
    font-weight: lighter;
    line-height: 20px;
}

svg {
    margin: 0 8px;
    font-size: 0.8rem;
}

.notify {
    background-color: var(--grey);
    padding: 8px 16px;
}
.success {
    background-color: #179617;
}
.fail {
    background-color: #ba1419;
}
