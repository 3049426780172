.bootcamp__content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    /* max-width: 600px; */
    width: 100%;
    /* max-height: 80vh;  */
    position: relative;
    text-align: center;
}

.bootcamp__close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.back__arrow {
    background: #4a148c;
    color: white;
    border-radius: 20px;
    padding: 5px 5px;
    margin-right: 10px;
    font-weight: 800;
}

.bootcamp__header {
    margin: 20;
    color: var(--dark-50);
    display: flex;
    justify-content: center;
    align-items: center;
}

.bootcamp__image {
    width: 60%;
    height: auto;
    border-radius: 8px;
    text-align: center;
}

h2 {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

h3 {
    border-top: 1px solid #ddd;
    margin-top: 50px;
    padding-top: 10px;
}

.regular__text {
    font-weight: 500;
}

.info {
    color: var(--dark-50);
    font-weight: 700;
}

.bootcamp__description, .bootcamp__start-date {
    margin: 10px 0;
}

.bootcamp__courses, .bootcamp__packages {
    margin: 20px 0;
    padding: 20px 20%;
    text-align: left;
}

.bootcamp__course, .bootcamp__package {
    margin-bottom: 15px;
}

.bootcamp__buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    background: #fff; 
    border-top: 1px solid #ddd; 
}

.bootcamp__button {
    background: #4a148c;
    color: #fff;
    padding: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.bootcamp__button:first-child {
    border: 1px solid var(--dark-50);
    color: #4a148c;
    background: transparent;
}

.btn-dark:hover {
    background: white;
    color: #4a148c;
    border: 1px solid #4a148c;
}

.btn-light:hover {
    background: rgb(66, 0, 128);
    color: white;
    border: 1px solid #4a148c;
}

.bootcamp__icon {
    margin-left: 8px;
}

.close-icon {
    font-size: 2.6em;
    color: #8c1414;
}

.error-message {
    color: #8c1414;
}

