section {
    padding: 48px;
    min-height: 420px;
    background-color: var(--white);
}

.section__heading {
    /* position: relative; */
    width: fit-content;
    max-width: 90%;
    margin: auto;
    margin-bottom: 32px;
    text-align: center;
    font-size: 1.625rem;
    color: var(--dark-100);
}

.section__heading .section__title {
    margin: 0;
    font-size: 1.825rem;
    position: relative;
    width: fit-content;
}

.section__heading .section__subtitle {
    margin: 0;
    margin-top: 8px;
}


.section__title::after {
    content: "";
    position: absolute;
    left: 0%;
    width: 66%;
    bottom: -4px;
    border-radius: 4px;
    border-bottom: 4px solid var(--dark-50);
}
/* -------------- SKILLS --------------- */
.skill_logos {
    position: relative;
    width: 80%;
    margin: auto;
    overflow: hidden;
    
    display: flex;
    justify-content: center;
    gap: 100px;
    border-radius: 12px;
    padding: 12px;
}
.marquee:before, .marquee:after {
    position: absolute;
    top: 0;
    width: 5%;
    height: 100%;
    content: "";
    z-index: 1;
  }
.marquee:before {
    left: 0;
    background: linear-gradient(to right, var(--white) 0%, transparent 100%);
}
.marquee:after {
    right: 0;
    background: linear-gradient(to left, var(--white) 0%, transparent 100%);
  }
/* -------------- PROJECTS -------------- */
.projects__section {
    background-color: var(--white);
    padding: 100px 80px;
    min-height: 720px;
}

.projects__section:nth-child(even) {
    background-color: var(--dark-100) !important;
    min-height: 640px;
}
.projects__section:nth-child(even) .section__heading {
    color: var(--white);
}

.hsc {
    display: flex;
    gap: 40px;
    justify-content: space-around;
    flex-wrap: wrap;
}

.hsc .card:nth-child(even) {
    background-color: var(--dark-100) !important;
}

.team__section .hsc {
    padding: 20px 80px;
}

/* ----------- KEY ADVANTAGE SECTION: WHY CHOOSE US --------- */
.advantage__section, .contact__section {
    background-color: var(--dark-100);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 40px;
}

.highlight {
    color: var(--light-50);
    font-size: 2rem;
}

.section__text {
    color: var(--white);
    width: 450px;
}

.section__text h2 {
    font-size: 2rem;
    margin: 0;
}

.section__text p {
    font-size: 1.2rem;
}

.section__text ul {
    font-size: 1.4rem;
    line-height: 2rem;
}

.advantage__section img {
    height: 400px;
    border-radius: 20px;
}

/* -------- CONTACT SECTION ----------- */
.contact__section {
    background-color: var(--whitish);
}

.contact__section .section__text {
    color: var(--dark-text);
}
.contact__section .section__text h1,
.contact__section .section__text svg 
{
    font-size: 48px;
    color: var(--dark-100);
    margin: 0;
}

.contact__section .section__text p {
    font-weight: 400;
}

/* --------- INTERESTS SECTION ------------- */
.endorsement__section {
    background-color: var(--light-50);
    padding: 80px;
}

.endorsement__section .section__title::after {
    display: none;
}

/* ------------ OTHERS -------------------- */
.btn.independent {
    width: fit-content;
    box-shadow: none;
    font-size: 1rem;
    background-color: transparent;
    color: var(--choco-gold);
    border: 2px solid var(--choco-gold);
}

.btn.independent:hover {
    background-color: var(--choco-gold);
    color: var(--white);
}

.redirect-btn {
    width: fit-content;
    margin: 24px auto;
}
