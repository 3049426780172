.card {
    width: 340px;
    height: 416px;
    padding: 40px;
    border-radius: 12px;
    background-color: var(--dark-50);
    display: flex;
    gap: 20px;
    flex-direction: column;
    transition: all 0.3s ease;
}

.card:hover {
    transform: translate(0, -10px);
    box-shadow: 10px 11px 4px 0 rgba(0,0,0,0.4);
}

.card__image {
    height: 248px;
    width: 56%;
    background-size: cover;
    border-radius: 8px;
    transition: all 0.3s ease;
}
.card:hover .card__image {
    transform: scale(1.04);
}

.card__details {
    display: flex;
    gap: 20px;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
}

.card__title {
    margin: 0;
    color: var(--white);
}

.card__description {
    color: var(--white);
    opacity: 80%;
    font-size: 14px;
}

.card__links {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
}

.card__link {
    padding: 8px 16px;
    text-decoration: none;
    background-color: transparent;
    border: 1px solid var(--white);
    color: var(--white);
    transition: all 0.4s ease;
}

.card__link:hover {
    background-color: var(--white);
    color: inherit;
}
