.banner {
    --billboard-height: 720px;
    height: var(--billboard-height);
    background: var(--brown-gradient);
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding-top: 100px;
}

.banner-text {
    text-align: center;
    color: var(--white);
    max-width: 700px;
    transform: translateX(calc( 0.5 * (100vw - 700px)));
}

.banner-text h1 {
    font-size: 4.5rem;
    /* margin-bottom: 20px; */
}

.banner-text p {
    font-size: 1.5rem;
    font-weight: 272;
    line-height: 2rem;
    margin-bottom: 30px;
}

.banner-image {
    transform: scale(0.8);
    margin-left: 40%;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

/* --------- CTA ------------- */
.cta-container {
    display: flex;
    justify-content: center;
    gap: 100px;
}

.btn {
    width: var(--cta-width);
    font-size: 1.2rem;
    border: 2px solid var(--dark-50);
    padding: 8px 24px;
    cursor: pointer;
    color: var(--white);
    background-color: var(--dark-50);
    box-shadow: var(--multiple-shadow);
    transition: all 0.3s ease-in-out;
}

.btn-pay {
    width: var(--cta-width);
    font-size: 1rem;
    border: 2px solid var(--dark-50);
    padding: 8px 24px;
    cursor: pointer;
    color: var(--white);
    background-color: var(--dark-50);
    box-shadow: var(--multiple-shadow);
    transition: all 0.3s ease-in-out;
}

.btn-transparent {
    color: var(--dark-50);
    background-color: transparent;
}

.btn:hover {
    color: var(--dark-50);
    background-color: var(--white);
    border: 2px solid var(--white);
}

.cta-container .btn:hover,
.cta-container .btn-transparent:hover {
    transform: translateY(-5px);
}
