/* Form element designs for payment modal */

.form__field {
    border-radius: 20px;
    border: 1px solid var(--dark-50);
}

.form__label {
    font-weight: 600;
    color: var(--dark-50);
    margin-top: 20px;
}

.tel {
    width: 100%;
    padding: 4%;
}

.error__message {
    font-size: small;
    color: red;
}

.form__title {
    margin-bottom: 40px;
    margin-top: 60px;
}

.modal__close {
    margin-bottom: 70px;
    font-size: 2em;
    font-weight: bolder;
    color: red;
}

.modal__close:hover {
    color: brown;
}

.select {
    padding: 4%;
    background-color: transparent;
    width: 80%;
}

.payment__button {
    align-self: center;
}