.bootcamp-card {
    width: 100%;
    max-width: 800px;
    height: auto;
    padding: 20px;
    border-radius: 12px;
    background-color: var(--dark-50);
    display: flex;
    gap: 5px;
    transition: all 0.3s ease;
    flex-direction: row;
}

.bootcamp-card:hover {
    transform: translate(0, -10px);
    box-shadow: 10px 11px 4px 0 rgba(0,0,0,0.4);
}

/* Modified to place image 50% on the left side of card */
.bootcamp-card__image {
    height: 100%;
    width: 30%;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.bootcamp-card:hover .bootcamp-card__image {
    transform: scale(1.04);
}

.bootcamp-card__details {
    display: flex;
    gap: 20px;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
}

.bootcamp-card__title {
    margin: 0;
    color: var(--white);
}

.bootcamp-card__description {
    color: var(--white);
    opacity: 80%;
    font-size: 14px;
    font-weight: 600;
}

.bootcamp-card__links {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
}

.bootcamp-card__link {
    padding: 8px 16px;
    text-decoration: none;
    background-color: transparent;
    border: 1px solid var(--white);
    color: var(--white);
    transition: all 0.4s ease;
}

.bootcamp-card__link:hover {
    background-color: var(--white);
    color: inherit;
}

.bootcamp__courses {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .bootcamp__course {
        color: var(--dark-50);
        font-size: .94em;
        padding: .5% 2%;
        background-color: var(--white);
        border-radius: 20px;
    }
}

/* Responsive Design - Display column on smaller screens */
@media (max-width: 900px) {
    .bootcamp-card {
        flex-direction: column;
    }

    .bootcamp-card__image {
        width: 80%;
        height: 200px;
    }

    .bootcamp-card__details {
        padding: 20px 0;
    }
}
