.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal__content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    max-height: 80vh; 
    overflow-y: auto; /* Vertical scroll */
    position: relative;
    text-align: left;
}

.modal__close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.modal__title {
    margin: 0;
}

.modal__image {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

h2 {
    /* margin-bottom: 150px; */
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

h3 {
    border-top: 1px solid #ddd;
    margin-top: 50px;
    padding-top: 10px;
}

.regular__text {
    font-weight: 500;
}

.info {
    color: var(--dark-50);
    font-weight: 700;
}

.modal__description, .modal__start-date {
    margin: 10px 0;
}

.modal__courses, .modal__packages {
    margin: 20px 0;
}

.modal__course, .modal__package {
    margin-bottom: 15px;
}

.modal__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 10px;
    background: #fff; 
    border-top: 1px solid #ddd; 
    position: sticky;
    bottom: 0;
}

.modal__button__payment {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    background: #fff; 
    border-top: 1px solid #ddd; 
    position: sticky;
    bottom: 0;
    margin-top: 20px
}

.modal__button {
    background: #4a148c;
    color: #fff;
    padding: 10px;
}

.modal__button:first-child {
    border: 1px solid var(--dark-50);
    color: #4a148c;
    background: transparent;
}

.modal__button:hover {
    background: var(--dark-70);
}

.modal__icon {
    margin-left: 8px;
}

.close-icon {
    font-size: 2em;
    color: #8c1414;
}

/* WebKit scrollbar styling */
.modal__content::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

.modal__content::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
    border-radius: 8px;
}

.modal__content::-webkit-scrollbar-thumb {
    background: var(--dark-50); /* Purple color for the scrollbar thumb */
    border-radius: 8px;
}

.modal__content::-webkit-scrollbar-thumb:hover {
    background: #4a148c; /* Darker purple on hover */
}
