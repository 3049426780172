.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .modal-content h4 {
    font-weight: 300;
  }
  
  .modal-content p {
    font-size: 1rem;
    font-weight: 500;
  }
  
  .modal-content a {
    color: blue;
    text-decoration: underline;
    font-weight: bold;
  }
  
  .modal-content .close-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-items: center;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    font-weight: bolder;
    cursor: pointer;
    color: red;
  }

  .modal-content .close-btn:hover {
    color: brown;
  }
  