
.profile__contact svg {
    height: 40px !important;
    color: var(--dark-50);
    transition: color 0.3s ease-in-out;
}
a svg:hover {
    color: var(--light-50);
}

.footer__content {
    color: var(--white);
    background-color: var(--dark-100);
}

.footer__info {
    padding: 30px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

.footer_copyright {
    padding: 20px;
    text-align: center;
    font-size: 12px;
    width: 90%;
    border-top: 2px solid var(--dark-50);
    margin: auto;
}

.footer__links {
    list-style: none;
    padding: 0;
}

.footer__links li {
    font-weight: 100;
    margin: 5px 10px;
}

.footer__links li a {
    color: var(--white);
    text-decoration: none;
}

.footer__links li a:hover {
    color: var(--dark-50);
}
