header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;
}

.header {
    min-height: 64px;
    padding: 8px 64px;
    background-color: var(--dark-50);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header .logo {
    width: 80px;
    padding: 8px;
    border-radius: 12px;
}

nav ul {
    display: flex;
    gap: 24px;
    list-style: none;
    transition: all 0.5s;
}

.nav__links {
    padding: 8px 0;
    color: var(--white);
    border-bottom: 2px solid transparent;
    transition: all 0.2s ease-in-out;
}

.nav__links:hover {
    border-bottom: 2px solid var(--dark-100);
    color: var(--white);
}

.nav__links.active {
    padding: 8px 16px;
    background-color: var(--dark-100);
    color: var(--white);
}