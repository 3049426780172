.navbar-container {
    display: none;
}

.navbar-toggle:hover {
    background-color: initial;
}

.icon-bar {
    display: block;
    width: 25px;
    height: 3px;
    margin-bottom: 5px;
    background-color: var(--white);
    border-radius: 2px;
    transition: transform 0.3s, opacity 0.3s;
}

.top-bar {
    transform-origin: top left;
}

.middle-bar {
    opacity: 1;
}

.bottom-bar {
    transform-origin: bottom left;
    margin-bottom: 0;
}

.navbar-toggle.active .top-bar {
    transform: translateY(-1px) translateX(4px) rotate(45deg);
}

.navbar-toggle.active .middle-bar {
    opacity: 0;
}

.navbar-toggle.active .bottom-bar {
    transform: translateY(0px) translateX(4px) rotate(-45deg);
}