/* -------------- RESPONSIVE -------------- */
/* IPAD AIR */
@media (max-width: 820px) {
    header {
        top: -2px;
    }
    div.header {
        flex-wrap: wrap;
        transition: height 0.4s ease-in-out;
    }
    div.header.active {
        height: fit-content;
        top: -2px;
        box-shadow: var(--multiple-shadow);
    }
    
    div.navbar-container {
        display: block; /* useless, probably */
    }
    .navbar-menu {
        display: none;
        width: 100%;
    }
    .navbar-menu.active {
        display: block;
    }
    nav ul {
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 16px;
        gap: 30px;
        font-size: larger;
        border-top: 1px solid var(--light-text);
    }
/*    banner */
    div.banner-text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
/*    card */
    div.card {
        flex: 0 1 280px;
    }

/*    advantage section */
    section.advantage__section {
        height: fit-content;
        padding: 80px 40px;
    }

    section.advantage__section img {
        height: 280px;
    }

/*    slide object */
    div.slide-object {
        width: 100%;
        height: 500px;
    }
/*    team section */
    .team__section .hsc {
        padding: 0;
    }

/*    contact */
    section.contact__section {
        flex-direction: column-reverse;
        height: fit-content;
    }
    section.contact__section .email-form {
        width: 72%;
    }

    .bootcamp__courses, .bootcamp__packages {
        padding: 20px;
    }

}

/* SAMSUNG GALAXY S20 ULTRA */
@media (max-width: 640px) {
    .header {
        padding: 8px 32px;
    }

    /* BANNER */
    div.banner-text {
        transform: translate(0);
        padding: 0 20px;
    }
    .banner-text h1{
        font-size: 3rem;
    }
    .banner-text p {
        width: 80%;
    }
    .banner-text .btn {
        gap: 40px;
    }
    .banner-image {
        margin-left: -60%;
    }
    .cta-container {
        flex-direction: column;
        gap: 20px;
    }

    /* projects section */
    .projects__section {
        padding: 80px 40px;
    }
    .projects__section:nth-child(even) {
        height: fit-content;
    }

    /* the team */
    .team__section {
        padding: 40px 20px;
    }
    .team__section .hsc {
        padding: 20px 0;
        gap: 30px 10px;
    }
    div.image-card {
        --card-height: 210px;
        width: 150px;
    }

    div.image-card:hover .image-card-img {
        height: 150px;
    }
    .image-card:hover .image-card-text {
        font-size: 0.625rem;
        padding: 5px 10px;
    }

    /* advantage section */
    section.advantage__section img {
        display: none;
    }

    section.advantage__section .section__text {
        width: fit-content;
    }

    /* endorsement section */
    section.endorsement__section {
        min-height: 100vh;
        padding: 80px 20px;
    }

    div.slide-screen {
        flex-direction: column-reverse;
    }
    div.slide-screen__text {
        padding-top: 10px;
    }

    div.slide-screen__text img {
        width: 20px;
    }
    img.slide-screen__image {
        height: 40%;
        width: 80%;
        align-self: center;
    }

    /* contact */
    section.contact__section .section__text {
        text-align: center;
    }
    div.section__text {
        width: fit-content;
    }

    .bootcamp__courses, .bootcamp__packages {
        padding: 10px;
    }

    /* footer */
    .footer__info {
        flex-direction: column;
        align-items: center;
    }

    div.profile__contact svg {
        height: 30px;
    }

    /* ------------- OTHERS ------------ */
    .warning {
        font-size: 1rem;
    }
}